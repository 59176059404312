import { Typography, Box, Container, Stack } from '@mui/material'
import { Warning as WarningIcon } from '@phosphor-icons/react/dist/ssr/Warning'
import {
	type ErrorResponse,
	isRouteErrorResponse,
	useParams,
	useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import { getErrorMessage } from '#app/utils/misc.tsx'

type StatusHandler = (info: {
	error: ErrorResponse
	params: Record<string, string | undefined>
}) => JSX.Element | null

export function GeneralErrorBoundary({
	defaultStatusHandler = ({ error }) => (
		<Box
			component="main"
			sx={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				minHeight: '100%',
				py: '64px',
			}}
		>
			<Container maxWidth="lg">
				<Stack spacing={6}>
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<WarningIcon
							color="var(--mui-palette-error-main)"
							fontSize="100"
							weight="fill"
						/>
					</Box>
					<Stack spacing={1} sx={{ textAlign: 'center' }}>
						<Typography variant="h4">
							{error.status}: {error.data}
						</Typography>
					</Stack>
				</Stack>
			</Container>
		</Box>
	),
	statusHandlers,
	unexpectedErrorHandler = error => <p>{getErrorMessage(error)}</p>,
}: {
	defaultStatusHandler?: StatusHandler
	statusHandlers?: Record<number, StatusHandler>
	unexpectedErrorHandler?: (error: unknown) => JSX.Element | null
}) {
	const error = useRouteError()
	captureRemixErrorBoundaryError(error)
	const params = useParams()

	if (typeof document !== 'undefined') {
		console.error(error)
	}

	return (
		<div className="container flex items-center justify-center p-20 text-h2">
			{isRouteErrorResponse(error)
				? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
						error,
						params,
					})
				: unexpectedErrorHandler(error)}
		</div>
	)
}
